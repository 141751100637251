.listtable {
  max-width: 800px;
  width: 100%;
  border-collapse: collapse;
  margin: 3rem 0rem 5rem 2rem;
}

.listrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--blue-500);
  padding: 1rem;
}

.songname {
  color: var(--blue-500);
}

.buttonlist {
  color: var(--white);
  background-color: var(--blue-500);
  padding: 0.3rem 0.6rem;
}
