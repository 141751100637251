.comment {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.comment > img {
  box-sizing: initial;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
}

.commentBox {
  flex: 1;
}

.commentContent {
  background-color: var(--blue-500);
  border-radius: 8px;
  padding: 1rem;
}

.commentContent header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.authorAndTime strong {
  display: block;
  font-size: 0.875rem;
  line-height: 1.6;
}

.authorAndTime time {
  display: block;
  font-size: 0.75rem;
  line-height: 1.6;
  color: var(--gray-400);
}

.commentContent header button {
  background: transparent;
  border: 0;
  color: var(--gray-300);
  cursor: pointer;
  line-height: 0;
  border-radius: 2px;
}

.commentContent p {
  margin-top: 1rem;
  color: var(--white);
  font-size: 1.075rem;
}

.commentBox footer {
  margin-top: 1rem;
}

.commentBox footer button {
  background-color: transparent;
  border: 0;
  color: var(--gray-400);
  cursor: pointer;
  display: flex;
  align-items: center;
}