.post {
  background: var(--blue-300);
  border-radius: 8px;
  padding: 2.5rem;
  margin-bottom: 10rem;
}

.post + .post {
  margin-top: 2rem;
}

.content {
  color: var(--blue-100);
  line-height: 1.675rem;
  margin-top: 1.5rem;
}

.content p {
  margin-top: 1rem;
}

.content a {
  font-weight: bold;
  color: var(--orange-900);
  text-decoration: none;
}

.content a:hover {
  color: var(--green-300);
}

.post > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.post > header time {
  font-size: 0.75rem;
  color: var(--gray-400);
}

.author {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}

.authorInfo strong {
  display: block;
  color: var(--gray-100);
  line-height: 1.6;
}

.authorInfo span {
  display: block;
  font-size: 0.875rem;
  color: var(--gray-400);
  line-height: 1.6;
}

.commentForm {
  color: var(--gray-900);
  width: 100%;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--gray-600);
}

.commentForm > strong {
  line-height: 1.6;
  color: var(--gray-900);
}

.commentForm textarea {
  width: 100%;
  background: var(--blue-200);
  border: 0;
  resize: none;
  height: 6rem;
  padding: 1rem;
  border-radius: 8px;
  color: var(--gray-900);
  line-height: 1.4;
  margin-top: 1rem;
}

.commentForm footer {
  visibility: hidden;
  max-width: 0;
}

.commentForm:focus-within footer {
  visibility: visible;
  max-width: none;
}

.commentForm button[type=submit] {
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 0;
  background-color: var(--blue-500);
  color: var(--gray-100);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.commentForm button[type=submit]:not(disable):hover {
  background-color: var(--blue-400);
}

.commentForm button[type=submit]:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.commentList {
  margin-top: 2rem;
}