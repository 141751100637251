.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.search input {
  width: 100%;
  height: 3.5rem;
  padding: 0 0.5rem;
  border: 1px solid var(--gray-500);
  border-radius: 0.25rem 0 0 0.25rem;
  color: var(--gray-700);
}

.searchButton[type=submit] {
  height: 3.5rem;
  border-radius: 0 0.25rem 0.25rem 0;
  background-color: var(--blue-500);
  border: 0;
  padding: 0 0.5rem;
  color: var(--white);
  font-weight: 600;
  transition: filter 0.2s;
}

.searchButton[type=submit]:not(disable):hover {
  background-color: var(--blue-400);
}

.searchButton[type=submit]:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}